<template>
  <div id="importData">
    <BaseActionButton
      v-if="!chipButton"
      v-tooltip.top="'import'"
      is-flat
      no-border
      icon="mdi-import"
      @click="isModalVisible = true"
    />
    <div
      v-if="chipButton"
      style="cursor: pointer"
      @click="isModalVisible = true"
    >
      <q-chip outline color="secondary" class="cursor-pointer secondary-hover">
        <q-avatar icon="mdi-import" color="secondary" text-color="white" />
        Import
      </q-chip>
    </div>
    <Modal
      v-model="isModalVisible"
      has-footer
      width="30vw"
      height="50vh"
      @input="isModalVisible = false"
    >
      <!-- title -->

      <template #title>Import Data</template>

      <!-- ... -->

      <template #default>
        <div class="text-caption text-grey q-mb-lg">
          Please download our sample excel file from the below link to get
          started. Edit the sample file then come back here and upload the
          edited file to import data.
        </div>
        <div>
          <a
            v-if="formId"
            :href="downloadURL"
            class="text-caption text-secondary"
          >
            Download Template Excel
          </a>
          <a
            v-else
            class="text-caption text-secondary cursor-pointer"
            @click.prevent="downloadTemplate"
          >
            Download Template Excel
          </a>
        </div>
      </template>

      <!-- footer -->

      <template #footer>
        <BaseButton
          is-flat
          label="cancel"
          class="q-mr-sm"
          @click="isModalVisible = false"
        />

        <BaseButton label="Upload" @click="upload" />
      </template>

      <!-- ... -->
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/common/popup/Modal.vue";

export default {
  name: "ImportPopup",

  components: { Modal },

  props: {
    dataImported: {
      type: Boolean,
      default: false,
    },

    chipButton: {
      type: Boolean,
      default: false,
    },

    formId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isModalVisible: false,
    };
  },

  computed: {
    downloadURL() {
      if (this.formId)
        return `${process.env.VUE_APP_API_URL}/form/downloadExcel/${this.$store.state.session.tenantId}/${this.formId}`;
      return "";
    },
  },

  watch: {
    dataImported: {
      immediate: true,
      deep: true,
      handler() {
        if (this.dataImported) {
          this.isModalVisible = false;
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    upload() {
      this.$emit("upload");
    },

    downloadTemplate(e) {
      e.preventDefault();
      this.$emit("downloadTemplate");
    },
  },
};
</script>

<style lang="scss" scoped>
#importData {
  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }
}
</style>
